import { Injectable } from '@angular/core';
import { Router } from '@angular/router'; 
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  private jwtHelper = new JwtHelperService();
  private loggedIn = new BehaviorSubject<boolean>(false);
  
  
  constructor(
    private router: Router, 
    private http: HttpClient,
    private storageService: StorageService
  ) { }

  // Get user session
  async getSession() {

    // ...
    // put auth session here
    // ... 
    const data = await this.storageService.get('access_token').then(
      data => data
    ); 
    if(data ===null || typeof(data)==='undefined'){
      return false;
    }  
    return this.isAuthenticated();
  }

  // Sign in
  signIn(email: string, password: string): Observable<any>{

    // // Sample only - remove this after real authentication / session
    // let sample_user = {
    //   email: email,
    //   password: password
    // }
    // return sample_user;
     
    let headers = new HttpHeaders({
      "Accept": 'application/json',
      'Content-Type': 'application/json',
    });

    let requestOptions = { headers: headers }

    let postData = {
      "username": email,
      "password": password, 
    }

    return this.http.post(environment.baseUrl + "public/login", postData, requestOptions);
        

    
  }

  // Sign up
  signUp(postData: any) {

    // Sample only - remove this after real authentication / session
    // let sample_user = {
    //   email: email,
    //   password: password
    // }

    let headers = new HttpHeaders({
      "Accept": 'application/json',
      'Content-Type': 'application/json', 
    }); 
  
   let requestOptions = { headers: headers }

    // return sample_user;
    return this.http.post(environment.baseUrl + "public/signup", postData, requestOptions);
  }

  // Sign out
  async signOut() { 
    // clean subscriptions, local storage etc. here  
    await this.storageService.remove('access_token');
    this.loggedIn.next(false);

    // Navigate to login
    await this.router.navigateByUrl('/signin');
  }

  isAuthenticated(): boolean {    
    return (!!this.jwtHelper.isTokenExpired(this.storageService.get('access_token')));
    // return !!this.jwtHelper.isTokenExpired(this.storageService.get('access_token'));
    
  }

  public setLoggedUser(user){
    if(!!user){
      this.storageService.set("user", user);
      this.loggedIn.next(true);
    }
  }

  getUserProfile(key: string): Promise<any> {
    return this.storageService.get(key);
  }

}
